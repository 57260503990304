import React, { useEffect, useState } from 'react'

import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks/web3'
import ConnectWallet from './components/ConnectWallet'
import Dashboard from './components/Dashboard'
import SignIn from './components/SignIn'
import { getSigner } from '../../utils'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 95vw;
  max-width: 478px;
  
  .fade-on-hover {
    transition: opacity 200ms ease;
    &:hover {
      opacity: 0.4;
    }
  }
`

export default function MainPage() {
  const { chainId, account, library } = useActiveWeb3React()
  const signer = library && account ? getSigner(library, account) : null

  const [bearerToken, setBearerToken] = useState<string>(sessionStorage.getItem('accountToken') || '')

  useEffect(() => {
    if ((sessionStorage.getItem('account') || '-') !== account && sessionStorage.getItem('accountToken')) {
      setBearerToken('')
      sessionStorage.setItem('accountToken', '')
      sessionStorage.setItem('account', account || '')
    }
  }, [])

  return (
    <Wrapper style={ account ? { maxWidth: bearerToken ? 'unset' : '640px' } : {} }>
      {bearerToken ? <Dashboard bearerToken={bearerToken} /> : <>
        {account ? <SignIn setBearerToken={setBearerToken} /> : <ConnectWallet />}
      </>}
    </Wrapper>
  )
}
