import React from 'react'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks/web3'
import GelatoLogo from '../../assets/svg/gelato-logo-text.svg'
import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import useTheme from '../../hooks/useTheme'
import useScrollPosition from '@react-hook/window-scroll'



const HeaderFrame = styled.div<{ showBackground: boolean }>`
  align-items: center;
  display: grid;
  flex-direction: row;
  grid-template-columns: 100px 1fr 0;
  justify-content: space-between;
  padding: 8px 1rem;
  position: relative;
  top: 0;
  z-index: 21;
  width: 100%;

  /* Background slide effect on scroll. */
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  mix-blend-mode: color;
  // box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  box-shadow: 0px 0px 0px 2px ${({ showBackground }) => (showBackground ? 'rgba(0, 0, 0, 0.2)' : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 8px 1rem;
    grid-template-columns: 100px 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 8px 1rem;
  `}
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 auto;
    width: auto;
  `};
`

const HeaderLinks = styled(Row)`
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  justify-self: center;
  margin: 0;
  overflow: auto;
  padding: 4px;
  width: fit-content;

  justify-self: flex-end;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 auto;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const GelatoIcon = styled.div`
  transition: transform 0.3s ease;
`

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const theme = useTheme()
  const scrollY = useScrollPosition()

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      <HeaderRow>
        <Title href=".">
          <GelatoIcon>
            <img width={'120px'} height={'46px'} src={GelatoLogo} alt="logo" style={{marginTop: '2px'}} />
          </GelatoIcon>
        </Title>
      </HeaderRow>
      <HeaderLinks
        style={{position: 'relative', overflow: 'initial'}}
      >
        <div style={{ pointerEvents: 'auto' }}>
          <Web3Status />
        </div>
      </HeaderLinks>
    </HeaderFrame>
  )
}
