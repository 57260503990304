
import styled, { createGlobalStyle } from 'styled-components';
import colors from './colors';

export const ThemedBackground = styled.div<{ backgroundColor?: string | undefined }>`
  position: fixed;
  top: 0;
  left: calc(-100vw / 2);
  right: 0;
  pointer-events: none;
  /* max-width: 100vw !important; */
  width: 200vw;
  height: 200vh;
  mix-blend-mode: color;
  background: ${({ backgroundColor }) =>
  `radial-gradient(50% 50% at 50% 50%, ${
    backgroundColor ? backgroundColor : '#fc077d10'
  } 0%, rgba(255, 255, 255, 0) 100%)`};
  transform: translateY(-100vh);
  will-change: background;
  transition: background 450ms ease;
`

export const FixedGlobalStyle = createGlobalStyle`
  a {
    color: ${colors(false).blue1}; 
  }
`

export const ThemedGlobalStyle = createGlobalStyle`
  html {
    color: ${({ theme }) => theme.text1};
    background-color: rgb(10, 37, 51);
  }

  body {
    min-height: 100vh;
    background-position: 0 -30vh;
    background-repeat: no-repeat;

  }
  
  .number-input::-webkit-outer-spin-button,
  .number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .date-picker {
    &&::-webkit-calendar-picker-indicator {
      cursor: pointer;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }
  }
  
  .a-style {
    cursor: pointer;
    color: ${({ theme }) => theme.blue4};
    
    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 0.7;
    }
  }
`
