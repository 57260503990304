import React, { useContext, useEffect, useState } from 'react'
import { LayoutWrapper } from 'atoms/LayoutWrapper'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../../../hooks/web3'
import { ButtonPrimary } from '@gelatonetwork/gelato-front-end-lib'
import { useHistory } from 'react-router-dom'
import { useWalletModalToggle } from '../../../../state/application/hooks'
import { TYPE } from '../../../../theme'
import GelTokenLogo from '../../../../assets/images/gel-token-logo.png'

const GradientText = styled.span`
  background: linear-gradient(90deg, #FFC78E 0%, #F1A2BC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
`

export default function ConnectWallet() {
  const { chainId } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  return (
    <LayoutWrapper>
      <div>
        <TYPE.heading1 mb={4} mt={3}>Gelato Analytics</TYPE.heading1>
        <ButtonPrimary
          altDisabledStyle style={{padding: '10px', width: '150px', margin: 'auto'}}
          onClick={toggleWalletModal}
        >
          Connect Wallet
        </ButtonPrimary>
        <TYPE.description mb={3} mt={4}>Connect your wallet to view data dashboard for Gelato Network.</TYPE.description>
      </div>
    </LayoutWrapper>
  )
}

