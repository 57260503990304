import React, { useContext, useEffect, useState } from 'react'
import { LayoutWrapper } from 'atoms/LayoutWrapper'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../../../hooks/web3'
import { ButtonPrimary } from '@gelatonetwork/gelato-front-end-lib'
import { useHistory } from 'react-router-dom'
import { useWalletModalToggle } from '../../../../state/application/hooks'
import { TYPE } from '../../../../theme'
import GelTokenLogo from '../../../../assets/images/gel-token-logo.png'
import { getSigner } from '../../../../utils'

const GradientText = styled.span`
  background: linear-gradient(90deg, #FFC78E 0%, #F1A2BC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
`

export default function SignIn({ setBearerToken }: { setBearerToken: any }) {
  const { chainId, account, library } = useActiveWeb3React()
  const signer = library && account ? getSigner(library, account) : null

  const message = 'Hello, please sign this message to authenticate on Gelato Dashboard.'

  const getSignature = async () => {
    if (library && library?.provider && library?.provider.request) {
      const signature = await library?.provider.request({ method: 'personal_sign',
        params: [ account, message ]
      })
      const base64Token = btoa(JSON.stringify({ signature, message }))
      setBearerToken(base64Token)
      sessionStorage.setItem('accountToken', base64Token)
      sessionStorage.setItem('account', account || '')
    }
  }

  useEffect(() => {
    getSignature()
  }, [])

  return (
    <LayoutWrapper>
      <div>
        <TYPE.heading1 mb={4} mt={3}>Gelato Analytics</TYPE.heading1>
        <ButtonPrimary
          altDisabledStyle style={{padding: '10px', width: '150px', margin: 'auto'}}
          onClick={getSignature}
        >
          Sign in
        </ButtonPrimary>
        <TYPE.description mb={3} mt={4}>Approve a message using your wallet to sign in.</TYPE.description>
      </div>
    </LayoutWrapper>
  )
}

