import { createReducer } from '@reduxjs/toolkit'
import { BigNumber } from '@ethersproject/bignumber'
import { setPool, setDetails, addPoolKeys, updatePoolData } from './actions'
import { SupportedNetwork } from 'constants/networks'
import { currentTimestamp } from 'utils'

export interface TokenInfo {
  address: string;
  symbol: string;
  decimals: number;
}
interface ILatestInfo {
  block: number;
  leftover0: BigNumber;
  leftover1: BigNumber;
  reserves0: BigNumber;
  reserves1: BigNumber;
  sqrtPriceX96: BigNumber;
  unclaimedFees0: BigNumber;
  unclaimedFees1: BigNumber;
}
interface FeeSnapshot {
  id: string;
  block: number;
  feesEarned0: BigNumber;
  feesEarned1: BigNumber;
}
export interface SupplySnapshotsEntity {
  block: number;
  id: string;
  reserves0: BigNumber;
  reserves1: BigNumber;
  sqrtPriceX96: BigNumber;
}
// PoolInfo is used to store formatted data fetched from thegraph
export interface PoolInfo {
  id: string;
  address: string;
  name: string;
  blockCreated: number;
  feeSnapshots?: Array<FeeSnapshot> | null;
  feeTier: number;
  liquidity: number;
  lowerTick: string;
  upperTick: string;
  manager: string; // TODO: Manager address should be address type
  managerFee: number;
  positionId: string;
  supplySnapshots?: Array<SupplySnapshotsEntity> | null;
  token0: TokenInfo;
  token1: TokenInfo;
  totalSupply: BigNumber;
  uniswapPool: string; // TODO: uniswapPool address should be address type
  latestInfo: ILatestInfo;
  sqrtPrice: number;
  lastTouchWithoutFees: string;
}
export interface PoolDetails {
  address: string;
  name: string;
  symbol: string;
  symbol0: string;
  symbol1: string;
  decimals: number;
  decimals0: number;
  decimals1: number;
  supply: BigNumber;
  supply0: BigNumber;
  supply1: BigNumber;
  balancePool: BigNumber;
  balance0: BigNumber;
  balance1: BigNumber;
  balanceEth: BigNumber;
  share0: BigNumber;
  share1: BigNumber;
  apr: number;
  sqrtPriceX96: BigNumber;
  lowerTick: number;
  upperTick: number;
  manager: string;
  feesEarned0: BigNumber;
  feesEarned1: BigNumber;
  lowerPrice: number;
  upperPrice: number;
  tokenPrice0?: number;
  tokenPrice1?: number;
}
export interface Market {
  name: string;
  identifier: string;
  has_trading_incentive: boolean;
}
export interface ConvertedLastOrConvertedVolume {
  btc: number;
  eth: number;
  usd: number;
}


export interface PoolDetailsMap {
  [key: string]: PoolDetails;
}
interface PoolState {
  byAddress: {
    [networkId: string]: {
      [address: string]: {
        data: PoolInfo | undefined,
        lastUpdated: number | undefined
      }
    }
  },
  pools: Array<PoolInfo>;
  poolsDetails: PoolDetailsMap;
}

export const initialState: PoolState = {
  byAddress: {
    [SupportedNetwork.ETHEREUM]: {}
  },
  pools: [],
  poolsDetails: {},
}
export default createReducer(initialState, (builder) =>
  builder
    .addCase(setPool, (state, action) => {
      state.pools = action.payload.pools;
    })
    .addCase(setDetails, (state, action) => {
      const p: PoolDetails = action.payload.poolDetails;
      // @ts-ignore
      state.poolsDetails[p.address] = p;
    })
    // add address to byAddress keys if not included yet
    .addCase(addPoolKeys, (state, { payload: { poolAddresses, networkId } }) => {
      poolAddresses.map((address) => {
        if (!state.byAddress[networkId][address]) {
          state.byAddress[networkId][address] = {
            data: undefined,
            lastUpdated: undefined,
          }
        }
      })
    })
    .addCase(updatePoolData, (state, { payload: { pools, networkId } }) => {
      pools.map(
        (poolData) =>
          (state.byAddress[networkId][poolData.address] = {
            ...state.byAddress[networkId][poolData.address],
            data: poolData,
            lastUpdated: currentTimestamp(),
          })
      )
    })
)