import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import ErrorBoundary from '../components/ErrorBoundary'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import MainPage from './MainPage'
import { ThemedBackground } from '../theme'
import { QueryClient, QueryClientProvider } from 'react-query'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 90px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 130px 16px 16px 16px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const client = new QueryClient()
export default function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Suspense fallback={null}>
          <Route component={GoogleAnalyticsReporter} />
          <Route component={DarkModeQueryParamReader} />
          <Web3ReactManager>
            <AppWrapper>
              <HeaderWrapper>
                <Header />
              </HeaderWrapper>
              <BodyWrapper>
                <ThemedBackground />
                <Popups />
                <QueryClientProvider client={client}>
                  <Switch>
                    <Route component={MainPage} />
                  </Switch>
                </QueryClientProvider>
                <Marginer />
              </BodyWrapper>
            </AppWrapper>
          </Web3ReactManager>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  )
}
