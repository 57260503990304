import { Colors } from '../styled'

const white = '#FFFFFF'
const black = '#000000'

export default function colors(darkMode: boolean): Colors {
  return {
    heading3: darkMode ? '#FFFFFF' : '#1E1E1E',
    heading6: darkMode ? '#FFFFFF' : '#212429',

    bgHover: darkMode ? '#212429' : '#F7F8FA',
    border: darkMode ? '#2C2F36' : '#E5E5E5',
    borderHover: darkMode ? '#C5C5C5' : '#E5E5E5',
    iconHover: darkMode ? '#212429' : '#F9F9F9',

    backIcon: darkMode ? '#FFFFFF' : '#525252',

    // base
    white,
    black,

    // text
    text1: darkMode ? '#FFFFFF' : '#000000',
    text2: darkMode ? '#C3C5CB' : '#565A69',
    text3: darkMode ? '#6C7284' : '#888D9B',
    text4: darkMode ? '#565A69' : '#C3C5CB',
    text5: darkMode ? '#2C2F36' : '#EDEEF2',
    text6: darkMode ? '#FFFFFF' : '#565A69',
    text7: darkMode ? '#FFFFFF' : '#868686',
    text8: darkMode ? '#fd90a7' : '#fd90a7',
    text9: darkMode ? '#99b2f7' : '#99b2f7',
    text10: darkMode ? '#c3c5ca' : '#575A68',

    // backgrounds / greys
    bg0: darkMode ? '#141619' : '#FFF',
    bg1: darkMode ? '#212429' : '#F7F8FA',
    bg2: darkMode ? '#2C2F36' : '#EDEEF2',
    bg3: darkMode ? '#40444F' : '#CED0D9',
    bg4: darkMode ? '#565A69' : '#888D9B',
    bg5: darkMode ? '#6C7284' : '#888D9B',
    bg6: darkMode ? '#270710' : '#270710',
    bg7: darkMode ? '#1b2330' : '#F7F8FA',
    bg8: darkMode ? '#222429' : '#F7F8FA',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

    //primary colors
    primary1: darkMode ? '#3872DD' : '#3872DD',
    primary2: darkMode ? '#4D8FEA' : '#4D8FEA',
    primary3: darkMode ? '#FF9FB2' : '#FF9FB2',
    primary4: darkMode ? '#C77F3F' : '#C77F3F',
    primary5: darkMode ? '#542432' : '#542432',

    // color text
    primaryText1: darkMode ? '#db5d6a' : '#db5d6a',

    // secondary colors
    secondary1: darkMode ? '#ff007a' : '#ff007a',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    // other
    red1: '#FD4040',
    red2: '#F82D3A',
    red3: '#D60000',
    red4: '#FF017A',
    red5: '#ff007a',
    red6: '#FF7C91',

    green1: '#27AE60',
    green2: '#8aeda0',

    yellow1: '#e3a507',
    yellow2: '#ff8f00',
    yellow3: '#F3B71E',
    yellow4: '#d4af8a',

    blue1: '#2172E5',
    blue2: '#5199FF',
    blue3: '#0F709F',
    blue4: '#84aaff',
    blue5: '#8AE1FF',

    error: '#FD4040',
    success: '#27AE60',
    warning: '#ff8f00',

    progressBarBackgroundColor: darkMode ? '#191b1f' : '#fff',
    progressBarBorderColor1: darkMode ? 'rgba(103, 105, 116, 0.2)' : 'rgba(103, 105, 116, 0.1)',
    progressBarBorderColor2: darkMode ? '#fff' : '#000',

    brandGradient: 'linear-gradient(90deg, #FF96A5 0%, #FFB98C 100%)',
  }
}
