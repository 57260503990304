import React from 'react'
import { CloseIcon } from 'theme'
import styled from 'styled-components'
import { RowBetween } from 'components/Row'
import { Text } from 'rebass'
import Modal from 'components/Modal'
import Column from 'components/Column'
import { ButtonSecondary } from '@gelatonetwork/gelato-front-end-lib'

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
`

const ErrorModal = ({ onDismiss, children }: { onDismiss: (visible: boolean) => void, children?: React.ReactNode }) => {

  return (
    <Modal
      isOpen={true}
      onDismiss={() => onDismiss(false)}
      maxHeight={70}
      minHeight={30}
    >
      <ContentWrapper>
        <div style={{ padding: '1rem' }}>
          <RowBetween>
            <Text fontWeight={500} fontSize={16} />
            <CloseIcon onClick={() => onDismiss(false)} />
          </RowBetween>
          <div style={{ textAlign: 'center' }}>
            <p style={{ wordBreak: 'break-word', marginBottom: '2rem' }}>
              {children}
            </p>
            <ButtonSecondary
              style={{ width: '160px', margin: 'auto' }}
              onClick={() => onDismiss(false)}
            >
              Close
            </ButtonSecondary>
          </div>
        </div>
      </ContentWrapper>
    </Modal>
  )
}

export default ErrorModal