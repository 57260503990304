interface NetworkData {
  name: string;
  tokens: [{
    ticker: string,
    address: string,
    decimals: number
  }]
}

export const NETWORK_TOKENS: { [chainId: number]: NetworkData } = {
  [1]: {
    name: 'Ethereum',
    tokens: [
      { ticker: 'ETH', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }
    ],
  },
  [3]: {
    name: 'Ropsten',
    tokens: [
      { ticker: 'ETH', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }
    ],
  },
  [42]: {
    name: 'Kovan',
    tokens: [
      { ticker: 'ETH', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }
    ],
  },
  [4]: {
    name: 'Rinkeby',
    tokens: [
      { ticker: 'ETH', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }
    ],
  },
  [5]: {
    name: 'Görli',
    tokens: [
      { ticker: 'ETH', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }
    ],
  },
  [0]: {
    name: 'Divider',
    tokens: [
      { ticker: 'ETH', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }
    ],
  },
  [137]: {
    name: 'Polygon',
    tokens: [
      { ticker: 'MATIC', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }
    ],
  },
  [250]: {
    name: 'Fantom',
    tokens: [
      { ticker: 'FTM', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }
    ],
  },
  [42161]: {
    name: 'Arbitrum',
    tokens: [
      { ticker: 'ETH', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }
    ],
  },
  [43114]: {
    name: 'Avalanche',
    tokens: [
      { ticker: 'AVAX', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }
    ],
  },
  [56]: {
    name: 'Binance',
    tokens: [
      { ticker: 'BNB', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }
    ],
  },
}
