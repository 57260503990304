import styled from 'styled-components/macro'

export const LayoutWrapperTransparent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95vw;
  max-width: 780px;
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
  padding: 30px;
  width: 95vw;
  max-width: 478px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.bg0};
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
`;
