import React, { useContext, useEffect, useMemo, useState } from 'react'
import { LayoutWrapper } from 'atoms/LayoutWrapper'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../../../hooks/web3'
import { ButtonGray, Spinner } from '@gelatonetwork/gelato-front-end-lib'
import { TYPE } from '../../../../theme'
import ErrorModal from 'components/ErrorModal'

const ResponsiveFlex = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: block;
    .amount-div {
      margin-bottom: 1rem;
    }
  `};
`

const ColumnWrapper = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  overflow-x: auto;

  .t-header-column {
    color: ${({ theme }) => theme.text3};
    
    th {
      padding: 20px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .t-column {
    font-size: 12px;
    text-align: left;
    gap: 20px;
    
    td {
      padding: 20px;
      white-space: nowrap;
      overflow: hidden;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }
  }
`

export default function Dashboard({ bearerToken }: { bearerToken: string }) {
  const { chainId, account, library } = useActiveWeb3React()

  const [loading, setLoading] = useState<boolean>(true)
  const [embedded, setEmbedded] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const [dashboardList, setDashboardList] = useState<string[]>([])

  async function getDashboardList() {
    const response = await fetch(`${process.env.REACT_APP_DASHBOARD_API}/dashboards/`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      //credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
    })
    if (response.status !== 200) {
      setLoading(false)
      setError(response.toString())
      return
    }
    const dashboardList = await response.json()
    setDashboardList(dashboardList.dashboards)
    setLoading(false)
    console.log(dashboardList)
    if (dashboardList.dashboards.length === 0) {
      setError('You don\'t have access to any dashboards')
    } else if (dashboardList.dashboards.length === 1) {
      embedDashboard(dashboardList.dashboards[0].id)
    }
  }

  const embedDashboard = async (dashId: string) => {
    console.log(dashId)
    if (!embedded) {
      setLoading(true)
      const response = await fetch(`${process.env.REACT_APP_DASHBOARD_API}/dashboards/${dashId}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        //credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bearerToken}`,
        },
      })
      if (response.status !== 200) {
        setLoading(false)
        setError(response.toString())
        return
      }
      const dashboardData = await response.json()
      console.log(dashboardData)
      const containerDiv = document.getElementById('embeddingContainer')
      const options = {
        url: dashboardData.dashboard.url,
        container: containerDiv,
        scrolling: 'no',
        height: '1400px',
        width: '100%',
        locale: 'en-US',
        footerPaddingEnabled: true,
      }
      // @ts-ignore
      QuickSightEmbedding.embedDashboard(options)
      setEmbedded(true)
      setLoading(false)
    }
  }

  const selectDashboard = (dashId: string) => {
    setEmbedded(false)
    embedDashboard(dashId)
  }

  useEffect(() => {
    getDashboardList()
  }, [])

  return (
    <>
      <LayoutWrapper style={{ maxWidth: 'unset', overflowX: 'auto', padding: '1rem 0 2rem 0', backgroundColor: '#0a2533' }}>
        {dashboardList.length > 1 && <div style={{ paddingBottom: '1rem' }}>
          {dashboardList.map((dash: any) => <ButtonGray
            key={1}
            style={{ width: '160px', margin: 'auto', padding: '1rem' }}
            onClick={() => selectDashboard(`${process.env.REACT_APP_DASHBOARD_API}/dashboards/${dash.id}`)}
          >
            {dash.name}
          </ButtonGray>)}
        </div>}
        {loading && <div>
            <Spinner style={{ margin: 'auto' }} />
        </div>}
        <div id="embeddingContainer" />
      </LayoutWrapper>
      {error !== '' && <ErrorModal onDismiss={() => setError('')}>
        {error}
      </ErrorModal>}
    </>
  )
}

