export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
  POLYGON = 137,
  FANTOM = 250,
  ARBITRUM = 42161,
  AVAX = 43114,
  BSC = 56,

  // ARBITRUM_ONE = 42161,
  // ARBITRUM_RINKEBY = 421611,
  // OPTIMISM = 10,
  // OPTIMISTIC_KOVAN = 69,
  // USDC_MATIC = 137,
}

export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.MAINNET,
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,
  SupportedChainId.POLYGON,
  SupportedChainId.FANTOM,
  SupportedChainId.ARBITRUM,
  SupportedChainId.AVAX,
  SupportedChainId.BSC,

  // SupportedChainId.ARBITRUM_ONE,
  // SupportedChainId.ARBITRUM_RINKEBY,
  // SupportedChainId.OPTIMISM,
  // SupportedChainId.OPTIMISTIC_KOVAN,
]

export const L1_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,
  SupportedChainId.AVAX,
  SupportedChainId.BSC,
] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

export const L2_CHAIN_IDS = [] as const

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]

export interface L1ChainInfo {
  readonly docs: string
  readonly explorer: string
  readonly infoLink: string
  readonly label: string
  readonly safeLabel?: string
  readonly backendApiLabel?: string
  readonly logoUrl?: string
  readonly rpcUrls?: string[]
  readonly nativeCurrency?: {
    name: string // 'Goerli ETH',
    symbol: string // 'gorETH',
    decimals: number //18,
  }
}

export interface L2ChainInfo extends L1ChainInfo {
  readonly bridge: string
  readonly logoUrl: string
  readonly statusPage?: string
}

type ChainInfo = { readonly [chainId: number]: L1ChainInfo | L2ChainInfo } &
  { readonly [chainId in SupportedL1ChainId]: L1ChainInfo }

export const CHAIN_INFO: ChainInfo = {
  [SupportedChainId.MAINNET]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Mainnet',
    safeLabel: 'ethereum',
    backendApiLabel: 'mainnet',
  },
  [SupportedChainId.RINKEBY]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://rinkeby.etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Rinkeby',
    safeLabel: 'rinkeby',
  },
  [SupportedChainId.ROPSTEN]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://ropsten.etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Ropsten',
    safeLabel: 'ropsten',
  },
  [SupportedChainId.KOVAN]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://kovan.etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Kovan',
    safeLabel: 'kovan',
  },
  [SupportedChainId.GOERLI]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://goerli.etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Görli',
    safeLabel: 'goerli',
  },
  [SupportedChainId.POLYGON]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://polygonscan.com/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Polygon Mainnet',
    safeLabel: 'polygon',
    backendApiLabel: 'matic',
    rpcUrls: ['https://polygon-rpc.com/'],
    nativeCurrency: { name: 'Polygon', decimals: 18, symbol: 'MATIC'},
  },
  [SupportedChainId.FANTOM]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://ftmscan.com/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Fantom Opera',
    safeLabel: 'fantom',
    rpcUrls: ['https://rpc.ftm.tools/'],
    nativeCurrency: { name: 'Fantom', decimals: 18, symbol: 'FTM'},
  },
  [SupportedChainId.ARBITRUM]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://arbiscan.io',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Arbitrum',
    safeLabel: 'arbitrum',
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
  },
  [SupportedChainId.AVAX]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://snowtrace.io',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Avalanche Network',
    safeLabel: 'avalanche',
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    nativeCurrency: { name: 'AVAX', decimals: 18, symbol: 'AVAX'},
  },
  [SupportedChainId.BSC]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://bscscan.com',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Binance Smart Chain',
    safeLabel: 'bsc',
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    nativeCurrency: { name: 'Binance Coin', decimals: 18, symbol: 'BNB'},
  }
}
