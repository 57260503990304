import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { getTokensAllowedToBeDeposited } from '../constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { Token } from '@uniswap/sdk-core'
import { FeeAmount, toHex } from '@uniswap/v3-sdk/dist/'
import { NETWORK_TOKENS } from '../constants/networkTokens'
import { BigNumber, ethers } from 'ethers'
import { getTokenUSDPriceGecko } from '../hooks/getTokenUSDPrice'
import { FXRate } from '../types'
import { ParamType } from '@ethersproject/abi'
import { CHAIN_INFO } from '../constants/chains'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export function ascendingAPR<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const currentTimestamp = () => new Date().getTime()


export const defaultToken = (chainId: any) => NETWORK_TOKENS[chainId as number].tokens[0]

export const getTokenSymbol = (paymentTokenId: string, chainId: number | undefined): string => {
  const depositTokens = getTokensAllowedToBeDeposited(chainId || 1)
  const tokenData = depositTokens.find((t: { address: string }) => t.address.toLocaleLowerCase() === paymentTokenId.toLocaleLowerCase())
  return tokenData.symbol
}

export const formatNumber = (amount: number, digits?: number): string => {
  return amount.toLocaleString(undefined, {
    maximumFractionDigits: digits || 2
  })
}

export async function getUSDAmountGecko(amount: string, symbol: string): Promise<number> {
  const fxRate = await getTokenUSDPriceGecko(symbol)
  const amountInDecimal = parseFloat(ethers.utils.formatUnits(amount || '0'))
  return Promise.resolve(amountInDecimal * fxRate)
}

export function getUSDAmountFXRates(amount: string, symbol: string, FXRates: Array<FXRate>): number {
  const fxRate = FXRates.find(r => r.symbol === symbol)?.USDPrice || 0
  const amountInDecimal = parseFloat(ethers.utils.formatUnits(amount || '0'))
  return amountInDecimal * fxRate
}

export function getTokenAmountFXRates(amount: string, symbol: string, FXRates: Array<FXRate>): number {
  const fxRate = FXRates.find(r => r.symbol === symbol)?.USDPrice || 0
  const amountInDecimal = parseFloat(ethers.utils.formatUnits(amount || '0'))
  return amountInDecimal / fxRate
}

export const formatTokenAmount = (amount: string, precisionDigits?: number): number => Math.floor(
  parseFloat(ethers.utils.formatUnits(amount)
  ) * (10 ** (precisionDigits || 6))) / (10 ** (precisionDigits || 6))

export const convertFuncArgToString = (funcArg: { _hex: string, _address: string, _bytes: string }): string => {
  if (funcArg._hex) return parseInt(funcArg._hex, 16).toString()
  if (funcArg._address) return funcArg._address
  if (funcArg._bytes) return funcArg._bytes
  return funcArg.toString()
}

export const checkIfJSONAndFormat = (stringToCheck: string): string | boolean | BigNumber | Record<string, unknown> => {
  try {
    const stringObj = JSON.parse(stringToCheck)
    if (typeof stringObj === 'number') return BigNumber.from(toHex(stringObj))
    if (typeof stringObj === 'boolean') return stringObj
    if (typeof stringObj === 'object') return stringObj
    return stringToCheck
  } catch (_) {
    return stringToCheck
  }
}

