import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import getLibrary from '../utils/getLibrary'
import { ALL_SUPPORTED_CHAIN_IDS } from '../constants/chains'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import GELATO_LOGO_URL from '../assets/svg/logo.svg'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID
// const DEFAULT_NETWORK_ID = Number(process.env.REACT_APP_DEFAULT_NETWORK_ID || 1)
const DEFAULT_NETWORK_ID = Number(137)

/*if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}*/

const NETWORK_URLS: {
  [chainId: number]: string
} = {
  [1]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [3]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  [4]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [5]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  [42]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  [137]: 'https://rpc-mainnet.maticvigil.com',
  [250]: 'https://rpc.ftm.tools',
  [42161]: 'https://arb1.arbitrum.io/rpc',
  [43114]: 'https://api.avax.network/ext/bc/C/rpc',
  [56]: 'https://bsc-dataseed.binance.org/',
}

const SUPPORTED_CHAIN_IDS = ALL_SUPPORTED_CHAIN_IDS

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: parseInt(sessionStorage.getItem('chainId') || '') || DEFAULT_NETWORK_ID,
  pollingInterval: 5000
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider))
}

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS
})

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
  rpc: NETWORK_URLS,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  // @ts-ignore
  pollingInterval: 15000
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URLS[1],
  appName: 'Gelato',
  appLogoUrl: GELATO_LOGO_URL
})
